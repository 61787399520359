import { useContext } from "react";
import { AppContext } from "../../../contexts/AppContext";
import SelectDocument from "../SelectDocument";
import { TermsProps } from "../Terms.interface";

import styles from "../Terms.module.css";
import ViewPDF from "../../../components/ViewPDF";

function TermsCL({ doc = "conditions" }: TermsProps) {
	const { translate, countryCodes } = useContext(AppContext);
	const copyPrivacyCL = doc === "privacy" ? ".CL" : "";

	const chileDocs = [
		{
			value: "conditions",
			name: translate("terms.document.conditions"),
		},
		{
			value: "privacy",
			name: translate("terms.document.privacy.CL"),
		},
	];

	return (
		<div className="container">
			<div className={styles.termsCols}>
				<SelectDocument value={doc} options={chileDocs} />
				<div className={styles.termsTextContainer}>
					<h1 className={styles.termsTitle}><b>{translate(`terms.document.${doc}${copyPrivacyCL}`)}</b></h1>
					<ViewPDF
						pdf={`${process.env.PUBLIC_URL}/docs/${countryCodes.CL}/${countryCodes.CL}-${doc}.pdf`}
					/>
				</div>
			</div>
		</div>
	);
}

export default TermsCL;
