import { useContext } from "react";
import { AppContext } from "../../../contexts/AppContext";
import SelectDocument from "../SelectDocument";
import { TermsProps } from "../Terms.interface";

import styles from "../Terms.module.css";
import ViewPDF from "../../../components/ViewPDF";

function TermsMX({ doc = "conditions" }: TermsProps) {
	const { translate, countryCodes } = useContext(AppContext);

	const mexicoDocs = [
		{
			value: "conditions",
			name: translate("terms.document.conditions"),
		},
		{
			value: "privacy",
			name: translate("terms.document.privacy"),
		},
		{
			value: "transparency",
			name: translate("terms.document.transparencyMX"),
		},
		{
			value: "prevention",
			name: translate("Política prevención LD/FT"),
		},
	];

	return (
		<div className="container">
			<div className={styles.termsCols}>
				<SelectDocument value={doc} options={mexicoDocs} />
				<div className={styles.termsTextContainer}>
					<h1 className={styles.termsTitle}><b>{translate(`terms.document.${doc}`)}</b></h1>
					<ViewPDF
						pdf={`${process.env.PUBLIC_URL}/docs/${countryCodes.MX}/${countryCodes.MX}-${doc}.pdf`}
					/>
				</div>
			</div>
		</div>
	);
}

export default TermsMX;
