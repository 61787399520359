import { useContext } from "react";
import FooterSelectLanguage from "../FooterSelectLanguage";
import FooterSocialLinks from "../FooterSocialLinks";

import styles from "../Footer.module.css";
import { AppContext } from "../../../contexts/AppContext";
import FooterLegalLinks from "../FooterLegalLinks";
import { RoutesNames } from "../../../constants";

function FooterCO() {
	const { countryCodes, translate } = useContext(AppContext);
	const year = new Date().getFullYear();

	const facebook = {
		url: "https://www.facebook.com/chiper.colombia",
		utm_source: "google",
		utm_medium: "cta",
		utm_term: "",
		utm_content: "",
		utm_campaign: "2021/9/23_ADQ_ALLCOL_GG_SL",
	};

	const instagram = {
		url: "https://www.instagram.com/chiper.colombia",
		utm_source: "google",
		utm_medium: "cta",
		utm_term: "",
		utm_content: "",
		utm_campaign: "2021/9/23_ADQ_ALLCOL_GG_SL",
	};

	const youtube = {
		url: "https://www.youtube.com/channel/UCqGvTi-OJZHFcif70xJXYbQ",
		utm_source: "google",
		utm_medium: "cta",
		utm_term: "",
		utm_content: "",
		utm_campaign: "2021/9/23_ADQ_ALLCOL_GG_SL",
	};

	const legalLinks = [
		{
			to: `/${countryCodes.CO}/${RoutesNames.terms}/${RoutesNames.conditions}`,
			name: translate("terms.document.conditions"),
			className: styles.legalLink,
		},
		{
			to: `/${countryCodes.CO}/${RoutesNames.terms}/${RoutesNames.privacy}`,
			name: translate("terms.document.privacy"),
			className: styles.legalLink,
		},
	];

	return (
		<div className={styles.footer}>
			<div className={styles.containerFooter}>
				<div className={styles.footerSocialLinksCols}>
					<div className={styles.footerSocialLinks}>
						<FooterSocialLinks facebook={facebook} instagram={instagram} youtube={youtube} />
						<FooterSelectLanguage />
					</div>
				</div>

				<div className={styles.footerLegalLinksCols}>
					<div className={styles.footerLegalLinks}>
						<FooterLegalLinks links={legalLinks} />
					</div>
				</div>
			</div>

			<div className={styles.footerChiperCols}>
				<p className={styles.chiperText}>&copy; {year} - CHIPER S.A.S.</p>
				<p className={styles.chiperText}>{translate("seo.footer.slogan")}</p>
			</div>
		</div>
	);
}

export default FooterCO;
