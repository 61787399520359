import { useContext } from "react";
import FooterSocialLinks from "../FooterSocialLinks";
import FooterSelectLanguage from "../FooterSelectLanguage";

import styles from "../Footer.module.css";
import { AppContext } from "../../../contexts/AppContext";
import FooterLegalLinks from "../FooterLegalLinks";
import { RoutesNames } from "../../../constants";

function FooterBR() {
	const { countryCodes, translate } = useContext(AppContext);
	const year = new Date().getFullYear();

	const facebook = {
		url: "https://www.facebook.com/Chiper.brasil",
		utm_source: "",
		utm_medium: "",
		utm_term: "",
		utm_content: "",
		utm_campaign: "",
	};

	const instagram = {
		url: "https://www.instagram.com/chiperbrasil/",
		utm_source: "",
		utm_medium: "",
		utm_term: "",
		utm_content: "",
		utm_campaign: "",
	};

	const legalLinks = [
		{
			to: `/${countryCodes.BR}/${RoutesNames.terms}/${RoutesNames.conditions}`,
			name: translate("terms.document.conditions"),
			className: styles.legalLink,
		},
		{
			to: `/${countryCodes.BR}/${RoutesNames.terms}/${RoutesNames.privacy}`,
			name: translate("terms.document.privacy"),
			className: styles.legalLink,
		},
	];

	return (
		<div className={styles.footer}>
			<div className={styles.containerFooter}>
				<div className={styles.footerSocialLinksCols}>
					<div className={styles.footerSocialLinks}>
						<FooterSocialLinks facebook={facebook} instagram={instagram} />
						<FooterSelectLanguage />
					</div>
				</div>

				<div className={styles.footerLegalLinksCols}>
					<div className={styles.footerLegalLinks}>
						<FooterLegalLinks links={legalLinks} />
					</div>
				</div>
			</div>

			<div className={styles.footerChiperCols}>
				<p className={styles.chiperText}>&copy; {year} - ChiperInc do Brasil Tecnologia S/A</p>
				<p className={styles.chiperText}>
          Rua Anhanguera, 860 - Sala 2, Piratininga, Osasco - SP, CEP 06.230-110
				</p>
			</div>
		</div>
	);
}

export default FooterBR;
