import { useContext, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { RoutesNames } from "../../constants";
import { AppContext } from "../../contexts/AppContext";
import FormProvider from "../../contexts/FormContext";
import AppIndex from "./AppIndex";
import Stores from "../../pages/Stores";
import Careers from "../../pages/Careers";
import Terms from "../../pages/Terms";
import About from "../../pages/About";
import NotFound from "../../pages/NotFound";
import Drivers from "../../pages/Drivers";

function AppRoutes() {
	const { countryCodes } = useContext(AppContext);
	const headerTop = document.getElementById("headerTop");
	useEffect(() => {
		if (headerTop) headerTop.scrollIntoView({ behavior: "smooth" });
		window.scrollTo({ top: 0 });
	});

	return (
		<Routes>
			<Route path="/" element={<AppIndex />} />
			{/* BRAZIL */}
			<Route path={`/${countryCodes.BR}`} element={<AppIndex country={countryCodes.BR} />} />
			<Route path={`/${countryCodes.BR}/${RoutesNames.stores}`} element={<Stores />} />
			<Route path={`/${countryCodes.BR}/${RoutesNames.careers}`} element={<Careers />} />
			<Route path={`/${countryCodes.BR}/${RoutesNames.terms}`} element={<Terms />}>
				<Route path={`:${RoutesNames.params.doc}`} element={<Terms />} />
			</Route>
			{/* COLOMBIA */}
			<Route path={`/${countryCodes.CO}`} element={<AppIndex country={countryCodes.CO} />} />
			<Route path={`/${countryCodes.CO}/${RoutesNames.stores}`} element={<Stores />} />
			<Route
				path={`/${countryCodes.CO}/${RoutesNames.drivers}`}
				element={(
					<FormProvider>
						<Drivers />
					</FormProvider>
				)}
			/>
			<Route path={`/${countryCodes.CO}/${RoutesNames.careers}`} element={<Careers />} />
			<Route path={`/${countryCodes.CO}/${RoutesNames.terms}`} element={<Terms />}>
				<Route path={`:${RoutesNames.params.doc}`} element={<Terms />} />
			</Route>
			{/* LATIN AMERICA */}
			<Route path={`/${countryCodes.MX}`} element={<AppIndex country={countryCodes.MX} />} />
			<Route path={`/${countryCodes.LA}/${RoutesNames.careers}`} element={<Careers />} />
			<Route path={`/${countryCodes.LA}/${RoutesNames.about}`} element={<About />} />

			{/* MEXICO */}
			<Route path={`/${countryCodes.MX}`} element={<Stores />} />
			<Route path={`/${countryCodes.MX}/${RoutesNames.stores}`} element={<Stores />} />
			<Route path={`/${countryCodes.MX}/${RoutesNames.careers}`} element={<Careers />} />
			<Route path={`/${countryCodes.MX}/${RoutesNames.terms}`} element={<Terms />}>
				<Route path={`:${RoutesNames.params.doc}`} element={<Terms />} />
			</Route>

			{/* CHILE */}
			<Route path={`/${countryCodes.CL}`} element={<AppIndex country={countryCodes.CL} />} />
			<Route path={`/${countryCodes.CL}`} element={<Stores />} />
			<Route path={`/${countryCodes.CL}/${RoutesNames.stores}`} element={<Stores />} />
			<Route path={`/${countryCodes.CL}/${RoutesNames.careers}`} element={<Careers />} />
			<Route path={`/${countryCodes.CL}/${RoutesNames.terms}`} element={<Terms />}>
				<Route path={`:${RoutesNames.params.doc}`} element={<Terms />} />
			</Route>
			{/* NOT FOUND */}
			<Route path="*" element={<NotFound />} />
		</Routes>
	);
}

export default AppRoutes;
